import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { PagesService } from 'src/app/core/services/pages/pages.service';

@Component({
  selector: 'app-medical-device',
  templateUrl: './medical-device.component.html',
  styleUrls: ['./medical-device.component.css']
})
export class MedicalDeviceComponent implements OnInit {
  ownership:any;
  leadership:any;
  outstanding:any;

  constructor(private pageService:PagesService,private apiService:ApiService,public sanitizer: DomSanitizer) { }



  ngOnInit() {
    // this.pageService.getCms("ownership").subscribe(
    //   (resp: any) => {
    //     this.ownership = resp.data;
    //   },
    //   (err: any) => {
    //     console.log(err);
    //   }
    // );
    // this.pageService.getCms("leadership").subscribe(
    //   (resp: any) => {
    //     this.leadership = resp.data;
    //   },
    //   (err: any) => {
    //     console.log(err);
    //   }
    // );
    this.pageService.getCms("disabilities-support-section1").subscribe(
      (resp: any) => {
        this.leadership = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.pageService.getCms("disabilities-support-section2").subscribe(
      (resp: any) => {
        this.outstanding = resp.data;
      },
      (err: any) => {
        console.log(err);
      }
    );


  }

}
