import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SignupComponent } from './components/user/signup/signup.component';
import { SigninComponent } from './components/user/signin/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModuleRouting } from './main-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthInterceptor } from 'src/app/core/auth/auth.interceptor';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AuthGuard } from 'src/app/core/auth/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule} from '@angular/material/card';
import { MatButtonModule} from '@angular/material/button';
import { NgxPaginationModule,PaginationInstance } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'src/app/core/core.module';
import { MatCheckboxModule, MatDividerModule, MatRadioModule } from '@angular/material';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwnershipComponent } from './pages/ownership/ownership.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ServiceDepartmentsComponent } from './pages/service-departments/service-departments.component';
import { MedicalDevicesComponent } from './pages/medical-devices/medical-devices.component';
import { StrategicComponent } from './pages/strategic/strategic.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { SampleProjectsComponent } from './pages/sample-projects/sample-projects.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CareersComponent } from './pages/careers/careers.component';
import { EventsComponent } from './pages/events/events.component';
import { HigherManagementStructureComponent } from './pages/higher-management-structure/higher-management-structure.component';
import { CareerDetailsComponent } from './pages/career-details/career-details.component';
import { DetailsComponent } from './pages/details/details.component';
import { NewsComponent } from './pages/news/news.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { MainComponent } from './main.component';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { NoSanitizePipe } from './pipes/nosanitizerpipe';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';
import { PopupComponent } from './components/popup/popup.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { GmcprivacyComponent } from './pages/gmcprivacy/gmcprivacy.component';
import { MedicalDeviceComponent } from './pages/medical-device/medical-device.component';

@NgModule({
  declarations: [
    MainComponent,
    SignupComponent,
    SigninComponent,
    HomeComponent,
    OwnershipComponent,
    HeaderComponent,
    FooterComponent,
    ServiceDepartmentsComponent,
    MedicalDevicesComponent,
    StrategicComponent,
    PartnerComponent,
    SampleProjectsComponent,
    ContactComponent,
    CareersComponent,
    EventsComponent,
    HigherManagementStructureComponent,
    NewsComponent,
    CareerDetailsComponent,
    DetailsComponent, ProjectDetailsComponent,
    BottomBarComponent,
    NoSanitizePipe,
    DeviceDetailsComponent,
    PopupComponent,
    PrivacyComponent,
    GmcprivacyComponent,
    MedicalDeviceComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ModuleRouting,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatNativeDateModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    CarouselModule,
    NgxPaginationModule,
    CoreModule,



  ],
  exports: [ HeaderComponent, FooterComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
    ApiService,
    AuthService,
    AuthGuard,
    DatePipe
],
})

export class MainModule { }
